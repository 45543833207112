import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import Utils from "./../components/Utils";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import "./../stylesheets/AdminSettings.css";
//
export default class AdminSettings extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleChange","handleSubmit", "fetchData");
    this.state = { error: false, data: [], firstLoad: true, isLoading: false };
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.props.app.sharedCache().setSettings(response["data"]); //set cache again!
      this.state.data = response['data'][0];
      this.state.firstLoad =  false;
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = {};
      this.state.firstLoad =  false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().getSettings(response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleChange(e) {
    Utils.defaultFormChangeHandler(e, this);
  }
  handleSubmit(e) {
    e.preventDefault();
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to save settings? ")) return;
    //
    this.startLoading();
    this.props.app.sharedAPIWrapper().editSettings(this.state.data ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Settings edited with success!");
        this.fetchData();
      } else {
        this.props.alertController.showErrorAlert("Error","Error while editing settings.",response);
        this.stopLoading();
      }
    });
  }
  //UI
  render() {
    return (
      <div className="AppPageContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>

        <Form className="formSettings" onSubmit={this.handleSubmit}>
          <h3>Settings:</h3><br></br>
          <Container>
              <Row className="justify-content-start">
                <Col className="col-4"><h4>{Utils.genFormField('longtravelkm',"Trips over this amount will be considered long distance:", true, true, "number", this, 0)}</h4></Col>
                <Col className="col-4 offset-2"><h4>{Utils.genFormField('valuekmdriven',"Amount paid to instructors per Km drive on personal vehicle when taking a course:", true, true, "number", this, 0)}</h4></Col>
              </Row><Row className="justify-content-start">
                <Col className="col-4"><h4>{Utils.genFormField('annualrsamaxexpenses',"Annual budget (not enforced) for expenses claimed by an RSA:", true, true, "number", this, 0)}</h4></Col>
                <Col className="col-4 offset-2"><h4>{Utils.genFormField('gstrate',"Gst rate used:", true, true, "number", this)}</h4></Col>
              </Row><Row className="justify-content-start">
                <Col className="col-4"><h4>{Utils.genFormField('emailpayments',"Email address where payment orders will be sent to:", true, true, "text", this, "example@example.com")}</h4></Col>
                <Col className="col-4 offset-2"><h4>{Utils.genFormField('adminemail',"Email address used to send automatic emails to admin:", true, true, "text", this, "example@example.com")}</h4></Col>
              </Row><Row className="justify-content-start">
                <Col className="col-4"><h4>{Utils.genFormField('timepaidshortdistance',"Travel time for short trips will be multiplied by this number:", true, true, "number", this, 0)}</h4></Col>
                <Col className="col-4 offset-2"><h4>{Utils.genFormField('timepaidlongdistance',"Travel time for long trips will be multiplied by this number:", true, true, "number", this, 0)}</h4></Col>
              </Row>
              <Row className="justify-content-start">
                <Col md={{ span: 2, offset: 4 }}>
                  <Button className="saveSettingsButton" variant="none" type="submit">Save</Button>
                </Col>
              </Row>
          </Container>
        </Form>
      </div>
    );
  }
}
//Navigation container implementation
export class AdminSettingsNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminSettings;
  }
}
