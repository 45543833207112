import React, { Component } from "react";
import { PhotoPicker } from 'aws-amplify-react';
import { Button, Tab, Tabs, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
//
import Utils from "./../../components/Utils";
import AdvisorLogFormAsyncImage from "./AdvisorLogFormAsyncImage";
//
export default class AdvisorLogFormImageGallery extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var receipts = (this.props.dataSource.state.data.receipts != undefined ? this.props.dataSource.state.data.receipts : []);
    var files = this.props.savedReceipts.concat(receipts);
    //
    return(
        <Container>
          <Row className="imageGallery justify-content-md-center">
             {
               files.map( receiptName => {
                 return(<AdvisorLogFormAsyncImage key={receiptName} fileName={receiptName} app={this.props.app} dataSource={this.props.dataSource}/>);
               })
             }
             {
               files.length == 0 ? <Col className="col-md-auto galleryMessageContainer">No images selected!</Col> : <></>
             }
          </Row>
          <PhotoPicker preview="hidden" onPick={this.props.dataSource.handleImageUpload} />
        </Container>
    );
  }
}

// className="childBox child3" eventKey="3" title="Files">
//  {editMode ?
//    <Row>
//      <Col>
//        <ImageUploader
//          ref={c => (this.imageUploader = c)}
//          withIcon={true} withPreview={true}
//          onChange={this.props.dataSource.handleImageChange}
//          buttonText='Choose images' withIcon={true}
//          maxFileSize={10485760}
//          />
//          <Button variant="primary" disabled={!haveImages} onClick={this.props.dataSource.handleImageUpload}> Upload </Button>
//      </Col>
//    </Row>
//      :
//    <></>
//  }
//    <Row>
//      <h6>Uploaded images:</h6>
//      {
//        safeReceipts.map( receiptName => {
//          return(
//            <div key={receiptName}>
//              <br></br>
//              <h5>{receiptName}</h5>
//              {editMode ?
//                <Button variant="primary" id={receiptName} onClick={this.props.dataSource.handleImageDelete}> Delete </Button>
//                :
//                <></>
//              }
//            </div>);
//        })
//      }
//      {
//        this.props.dataSource.state.unsavedReceipts.map( receiptName => {
//          return(
//            <div key={receiptName}>
//              <br></br>
//              <h5>{receiptName}</h5>
//              {editMode ?
//                <Button variant="primary" id={receiptName} onClick={this.props.dataSource.handleImageDelete}> Delete </Button>
//                :
//                <></>
//              }
//            </div>);
//        })
//      }
//    </Row>
//  </Tab>
