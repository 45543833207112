import React, { Component } from "react";
import { Container, Row, Col  } from "react-bootstrap";
//
import "./../../stylesheets/AdvisorLogGauge.css";
//
export default class AdvisorLogGauge extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var percentage = Math.min((this.props.percentage ? this.props.percentage.toFixed(1) : 0),100);
    return (
      <div className="logGaugeBackground">
        <Container className="logGaugeContainer">
            <Row className="logGaugeContainerBackground">
              <Col className="col">
                <div className="logGaugeBar">
                  <div style={{width: percentage + "%"}} className="logGaugeUsedBackground"></div>
                  <div className="logGaugeBarText">
                    <h2 className="logGaugeMainLabel">{percentage}%</h2>
                    <h4 className="logGaugeSecondaryLabel">of your monthly budget</h4>
                  </div>
                </div>
              </Col>
              <Col md={1} className="logGaugeButtonContainer my-auto">
                {this.props.children}
              </Col>
            </Row>
        </Container>
      </div>);
    }
}
