var md5 = require('md5');
//
export default class APIUtils  {
  constructor(props) {}
}

//App utils
APIUtils.getAPPVersion = function() {
  return "0.1/beta";
}
APIUtils.encodeUploadFile = function(userID, filename) {
  var timestamp = Date.now();
  return md5(userID + filename + timestamp);
}
//API
  //Currently used to encode Log entries
  //Currently used to encode users
APIUtils.encodeForAPI = function(obj,cache) {
  obj = Object.assign({},obj);
  for (var key in obj) {
    if (key == "activitydate") {
      obj[key] = APIUtils.formatToGSI1SK(new Date(obj[key]))
    } else if (key == "socialmediasubmission" || key == "safetyclimatetool") {
      obj[key] = (obj[key] == true ? 1 : 0);
    } else if (key == "submissionyearmonth") {
      obj[key] = "" + obj[key].slice(0,4) + obj[key].slice(5,7);
    } else if (key == "type") {
      obj[key] = (obj[key] == true ? "Admin" : "User");
    } else if (key == "ctid") {
      var catKey = "";
      cache.getLGCategories().forEach(cat => {
        if (cat["description"] == obj[key]) { catKey = cat["pk"]; }
      });
      obj[key] = catKey;
    } else if (key == "highrisk") {
      for (var riskkey in obj[key]) {
        obj[key][riskkey] = (obj[key][riskkey] == true ? 1 : 0);
      }
    }
  } return obj;
}
APIUtils.decodeAPIData = function(obj,cache) {
  var retValue = [];
  //
  obj.forEach(record => {
    for (var key in record) {
      if (key == "activitydate") {
        record[key] = APIUtils.parseGSI1SK(record[key]);
      } else if (key == "socialmediasubmission" || key == "safetyclimatetool") {
        record[key] = (record[key] == 1 ? true : false);
      } else if (key == "submissionyearmonth") {
        record[key] = record[key].slice(0,4) + "-" + record[key].slice(4,6);
      } else if (key == "ctid") {
        var value = "";
        cache.getLGCategories().forEach(cat => {
          if (cat["pk"] == record[key]) { value = cat["description"]; }
        });
        record[key] = value;
      } else if (key == "highrisk") {
        for (var riskkey in obj[key]) {
          obj[key][riskkey] = (obj[key][riskkey] == 1 ? true : false);
        }
      }
    } retValue.push(record);
  });
  return retValue;
}
APIUtils.decodeAPIUserData = function(obj) {
  var cleanUserList = [];
  //For all userSettings
  obj.forEach( userObj => {
    var newUser = {};
    //for each key in user object
    newUser["username"] = userObj["Username"];
    newUser["type"] = (userObj["GroupName"] == "Admin" ? true : false);
    //parse other values in Attributes!
    for (var attrIdx in userObj["Attributes"]) {
      var attr = userObj["Attributes"][attrIdx];
      if (attr["Name"] == "name") { newUser["name"] = attr["Value"]; }
      else if (attr["Name"] == "email") { newUser["email"] = attr["Value"]; }
      else if (attr["Name"] == "sub") { newUser["id"] = attr["Value"]; }
    }
    //parse other values in extraData!
    var extraData = userObj["extraData"];
    if (extraData !== undefined && extraData.length > 0) extraData = extraData[0];
    for (var extraKey in extraData) {
      if (extraKey == "hourlyrate") { newUser["hourlyrate"] = extraData[extraKey]; }
      else if (extraKey == "gstnumber") { newUser["gstnumber"] = extraData[extraKey]; }
      else if (extraKey == "timepaidshortdistance") { newUser["timepaidshortdistance"] = extraData[extraKey]; }
      else if (extraKey == "businessname") { newUser["businessname"] = extraData[extraKey]; }
    }
    //
    cleanUserList.push(newUser);
  });
  return cleanUserList;
}
APIUtils.formatToGSI1SK = function(date, excludeDay) {
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  var day = (excludeDay ? "" : String("0" + date.getDate()).slice(-2));
  //month is index from 0 to 11
  var month = String("0" + (date.getMonth() + 1)).slice(-2);
  return ("" + date.getFullYear() + month + day);
}
APIUtils.formatStringToGSI1SK = function(yearStr, monthStr) {
  var month = ("0" + monthStr).slice(-2);
  return ("" + yearStr + month);
}
APIUtils.parseGSI1SK = function(dateString, excludeDay) {
  if (dateString == undefined) return "Invalid Date";
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  var day = (excludeDay ? "" : dateString.slice(6,8));
  //month is index from 0 to 11
  var month = dateString.slice(4,6);
  //
  return (dateString.slice(0,4) + "-" + month + "-" + day);
}


//
APIUtils.getTotalCostFromLGs = function(data) {
  var val = 0;
  for (var i = 0; i < data.length; i++) {
    var obj = data[i];
    if (obj["totalclaim"] != undefined) val += parseFloat(obj["totalclaim"]);
  } return val;
}
APIUtils.getAdvisorExpensesPercentageFromLGs = function(data, cache) {
  var maxAnnualExpense = cache.getLoggedAdvisorAnnualMaxExpense();
  if (!maxAnnualExpense || !data || data.length == 0) return 0;
  //Get all expense of entries
  var totalExpenses = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i]["totalclaim"] != undefined)
      totalExpenses += parseFloat(data[i]["totalclaim"]);
  } return (totalExpenses / (maxAnnualExpense/12.0)) * 100.0;
}
