import React, { Component } from "react";
import { Container } from "react-bootstrap";
//
import "./../../stylesheets/CommonOverlayBox.css";
//
export default class CommonOverlayBox extends Component {
  constructor(props) {
    super(props);
  }
  //UI
  render() {
    //render
    return (<Container className={"overlayContainer " + this.props.className}>
              {this.props.children}
            </Container>);
  }
}
