import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import Utils from "./../components/Utils";
//
import CustomComponent from "./../components/CustomComponent";
import AdminUserDetails from './subviews/AdminUserDetails';
import "./../stylesheets/AdminUserManager.css";
//
export default class AdminUserManager extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleDeleteUser","handleAddUser","handleResetUserPassword", "fetchData");
    this.state = { error: false, data: [], firstLoad: true, isLoading: false };
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.props.app.sharedCache().setUsers(response["data"]); //cache
      this.state.data = response['data'];
      this.state.firstLoad =  false;
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.firstLoad =  false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().getUsers(response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //Util
  findUser(usr) {
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i]["username"] == usr)
        return this.state.data[i];
    } return null;
  }
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleAddUser() {
    this.props.navigationController.pushView(
      <AdminUserDetails actionType="ADD" app={this.props.app} alertController={this.props.alertController}/>
    );
  }
  handleEditUser(usr) {
    var userObj = this.findUser(usr);
    this.props.navigationController.pushView(
      <AdminUserDetails userData={userObj} actionType="EDIT" app={this.props.app} alertController={this.props.alertController}/>
    );
  }
  handleDeleteUser(usr) {
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to delete user " + usr)) return;
    //proceed
    this.startLoading();
    this.props.app.sharedAPIWrapper().deleteUser(usr ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success", "User deleted with success!");
        this.fetchData();
      } else {
        this.props.alertController.showErrorAlert("Error", "Error while deleting user.", response);
        this.stopLoading();
      }
    });
  }
  handleResetUserPassword(usr) {
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to reset user " + usr + " password?")) return;
    //proceed
    this.startLoading();
    this.props.app.sharedAPIWrapper().resetUserPassword(usr ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success", "User password reseted with success!");
        this.fetchData();
      } else {
        this.props.alertController.showErrorAlert("Error", "Error while reseting user password.", response);
        this.stopLoading();
      }
    });
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      noDataText: "No data!",
      loadingText: "Loading...",
      keyField :'username',
      loading:this.state.isLoading
    };
    const columns = [
    {
      Header: 'Username',
      accessor: 'username'
    }, {
      Header: 'Email Description',
      accessor: 'email'
    }, {
      Header: 'Name',
      accessor: 'name'
    }, {
      Header: 'Is Admin',
      accessor: 'type',
      Cell: props => {
        return(<span>{(props.value ? "Yes" : "No")}</span>);
      }
    }, {
      Header: '',
      accessor: 'username',
      Cell: props => {
        return (<span className='tableButtonContainer'>
                  <Button className="tableDeleteButton" variant="danger"
                          onClick={this.handleDeleteUser.bind(this,props.value)} >Delete</Button>
                  <Button className="tableResetPassButton" variant="danger"
                          onClick={this.handleResetUserPassword.bind(this,props.value)} >Reset pass</Button>
                        <Button className="tableEditButton" variant="none"
                          onClick={this.handleEditUser.bind(this,props.value)} >Edit</Button>
                </span>);
      }
    }];
    //render
    return (
      <div className="AppPageContainer">
          <CommonLoadingView isLoading={this.state.isLoading}/>
          <Button variant="primary" onClick={this.handleAddUser}>Add user</Button>
          <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props}/>
        </div>
      );
  }
}
//Navigation container implementation
export class AdminUserManagerNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminUserManager;
  }
}
