import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "react-bootstrap";
import autoBind from 'react-autobind';
import checkboxHOC from "react-table/lib/hoc/selectTable";
//
import CustomComponent from "./../../components/CustomComponent";
import LogForm from "./AdvisorLogForm";
import "./../../stylesheets/AdvisorLogTable.css"
//
const CheckboxTable = checkboxHOC(ReactTable);
export default class AdvisorLogTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleSelection","handleToogleSelectAll","handleIsSelected","handleDeleteLog","handleEditLog");
    this.state = { selection: [], selectAll: false };
  }
  selectAllRows(data) {
    this.handleToogleSelectAll(data);
  }
  getSelectedIDS() { return this.state.selection; }
  //UI
  render() {
    const data = (this.props.data == null ? [] : this.props.data);
    const props = {
      selectAll: this.state.selectAll,
      isSelected: this.handleIsSelected,
      toggleSelection: this.handleSelection,
      toggleAll: this.handleToogleSelectAll,
      selectType: "checkbox",
      minRows: 6,
      showPagination: false,
      noDataText: "No data!",
      loadingText: "Loading...",
      keyField :'sk',
      loading:this.state.isLoading
    };
    const columns = [
    {
      Header: 'Activity Date',
      accessor: d => (d['activitydate'] ? d['activitydate'].split("-").join("/") : ""),
      id: "activitydate"
    }, {
      Header: 'Activity Description',
      accessor: 'description'
    }, {
      Header: 'Company Name',
      accessor: 'companyname'
    }, {
      Header: 'Total Travel Costs($)',
      accessor: 'travel.totaltravel'
    }, {
      Header: 'Total Consulting Costs($)',
      accessor: 'consulting.consultingpayment'
    }, {
      Header: 'Other Costs($)',
      accessor: 'othercosts'
    }, {
      Header: 'Total Costs($)',
      accessor: 'totalclaim'
    }, {
      Header: '',
      accessor: '',
      sortable: false,
      Cell: props => {
        return (<span className='tableButtonContainer'>
                  <Button className="tableEditButton" variant="light"
                          onClick={this.handleEditLog.bind(this,props.value)} >Edit</Button>
                  <Button className="tableDeleteButton" variant="danger"
                          onClick={this.handleDeleteLog.bind(this,props.value)} >Delete</Button>
                </span>);
    }
  }];

    //render
    return (<div className="AppPageContainer">
              <CheckboxTable keyField='username' data={data}
                            className="-highlight v-100" columns={columns}
                            {...props}/>
              </div>);
  }
  //Action handler
  handleEditLog(obj) {
    this.props.navigationController.pushView(
      <LogForm actionType="EDIT" data={Object.assign({},obj)} app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  handleDeleteLog(obj) {
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to delete this entry? ")) return;
    //
    this.props.app.sharedAPIWrapper().deleteLogEntry(obj ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Log entry edited with success!");
        this.props.deletedUpdateHandler();//ask for UI update
      } else {
        this.props.alertController.showErrorAlert("Error","Error while editting log entry.",response);
      }
    });
  }
  //Table datasource
  handleSelection = (key, shift, row) => {
    // start off with the existing state
    var selection = [...this.state.selection];
    var keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else { selection.push(key); }
    // update the state
    this.state.selection = selection;
    this.state.selectAll = (this.state.selection.length == this.props.data.length);
    this.props.uiUpdateHandler();
  };
  handleToogleSelectAll = (optionalData) => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    optionalData = (optionalData === undefined ? this.props.data : optionalData);
    //push all
    if (selectAll) {
      optionalData.forEach(item => { selection.push(item['sk']); });
    }
    this.state.selectAll = selectAll;
    this.state.selection = selection;
    this.props.uiUpdateHandler();
  };
  handleIsSelected = key => {
    return this.state.selection.includes(key);
  };
}
