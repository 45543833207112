import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CommonLoadingView from "./subviews/CommonLoadingView";
import LogoBW from "./../images/logoBW.png";
import "./../stylesheets/CommonLogin.css";
//
export default class CommonResetPassword extends Component {
  constructor(props) {
    super(props);
    autoBind(this,"validateForm","handleChangePassword");
    this.state = {
      password: "",
      passwordConfirmation: "",
      requiresPasswordChange: false,
      confirmationCode: "",
      username: "",
      isLoading: false
    };
  }
  //Utils
  validateForm() {
    return this.state.passwordConfirmation.length > 0 && this.state.password.length > 0 &&
           this.state.confirmationCode.length > 0 && this.state.username.length > 0;
  }
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  handleChangePassword = async event => {
    event.preventDefault();
    //
    if (this.state.passwordConfirmation != this.state.password) {
      this.props.alertController.showErrorAlert("Error!","Passwords dont match!");
      return;
    }
    this.startLoading();
    // Collect confirmation code and new password, then
    var data2 = await Auth.forgotPasswordSubmit(this.state.username, this.state.confirmationCode, this.state.password)
    .then(data => {
      this.props.app.setAuthenticatedUser(true); //fake login process
      this.props.alertController.showSuccessAlert("Success!","Password updated!");
      this.props.history.push("/"); //push to main page
    })
    .catch(err => {
      this.props.alertController.showErrorAlert("Error!",err["message"]);
      this.props.history.push("/"); //push to login page again
      this.state.isLoading = false;
      this.setState(this.state);
      this.stopLoading();
    });
	}
  //UI
  render() {
    var isLoading = this.state.isLoading || this.props.app.state.isAuthenticating;
    return (
      <div className="AppPageContainer">
        <Container className="authBox">
          <CommonLoadingView isLoading={isLoading}/>
          <Row className="justify-content-md-center">
            <Col className="col-md-auto">
              <img className="authLogo" src={LogoBW}/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="col-md-auto">
              <h6 className="authLabel">Please, set a new password!</h6>
            </Col>
          </Row>
          <Form onSubmit={this.handleChangePassword}>
          <Form.Group controlId="username">
            <Form.Control autoFocus value={this.state.username} className="inputFields" placeholder="Username"
                          onChange={this.handleChange}/>
          </Form.Group>
            <Form.Group controlId="confirmationCode">
              <Form.Control value={this.state.confirmationCode} className="inputFields" placeholder="Confirmation code"
                            onChange={this.handleChange} type="code"/>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control value={this.state.password} className="inputFields" placeholder="Password"
                            onChange={this.handleChange} type="password"/>
            </Form.Group>
            <Form.Group controlId="passwordConfirmation">
              <Form.Control value={this.state.passwordConfirmation} className="inputFields" placeholder="Password confirmation"
                            onChange={this.handleChange} type="password"/>
            </Form.Group>
            <Button block disabled={!this.validateForm()}
                    type="submit" className="loginButton">
                    Change password
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}
