import React, { Component } from "react";
import ReactTable from "react-table";
import { DropdownButton, Dropdown, Button, Container, Row, Col } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import Utils from "./../components/Utils";
import CommonLogTable from "./subviews/CommonLogTable";
//
//import "./../stylesheets/admSubmissions.css";
//
export default class AdminReportManagement extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleRowClick","handleApproveSubmission","handleRejectSubmission", "fetchData");
    Utils.setReportDateRange(this);
    this.state = {error: false, data: [], firstLoad: true, isLoading: false };
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.state.data = response['data'];
      this.state.firstLoad =  false;
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.firstLoad =  false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading()
    this.props.app.sharedAPIWrapper().fetchMonthlyReportsForApproval(response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleRowClick(event, row) {
    this.props.navigationController.pushView(
      <CommonLogTable reportMR={row.original} app={this.props.app}
                       alertController={this.props.alertController}/>
    );
  }
  handleRejectSubmission(data, e) {
    e.stopPropagation(); //prevents click on the table cell
    this.handleSubmission(data, false);
  }
  handleApproveSubmission(data, e) {
    e.stopPropagation(); //prevents click on the table cell
    this.handleSubmission(data, true);
  }
  handleSubmission(e,approved) {
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to " + (approved ? "approve" : "reject") + " this submission? ")) return;
    //Action
    this.startLoading();
    this.props.app.sharedAPIWrapper().submitMonthlyReportDecision(e, approved ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Monthly report " + (approved ? "approved" : "rejected") + " with success!");
        this.fetchData();
      } else {
        this.props.alertController.showErrorAlert("Error","Error while " + (approved ? "approving" : "rejecting") +  " monthly report.",response);
        this.stopLoading();
      }
    });
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      noDataText: "No data!",
      loadingText: "Loading...",
      keyField :'pk',
      loading:this.state.isLoading,
      getTrProps: (s, r) => {
        const selected = true;
        return {
          onClick: (e) => { this.handleRowClick(e, r) }
        };
      }
    };
    const columns = [
    {
      Header: 'Submission Year',
      accessor: d => (d['sk'] ? d['sk'].slice(0,4) : ""),
      id: "sky"
    },{
      Header: 'Submission Month',
      id: 'skm',
      accessor: d => Utils.getMonthName(d['sk'] ? d['sk'].slice(4,6) : "")
    },{
      Header: 'Advisor Name',
      id: 'pku',
      accessor: d => (d['pk'] ? this.props.app.sharedCache().findUserNameByID(d["pk"].replace("MS::","")) : "")
    },{
      Header: 'Total Costs($)',
      id: 'totalcost',
      accessor: 'totalclaim'
    },{
      Header: '',
      accessor: 'pk',
      Cell: props => {
        return (<span className='tableButtonContainer'>
                  <Button className="tableDeleteButton" variant="danger" onClick={this.handleRejectSubmission.bind(this,props.value)} >Reject</Button>
                  <Button className="tableEditButton" variant="success" onClick={this.handleApproveSubmission.bind(this,props.value)} >Approve</Button>
                </span>);
      }
    }];
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <ReactTable data={data} className="v-100 -highlight" columns={columns} {... props}/>
    </div>);
  }
}
//Navigation container implementation
export class AdminReportManagementNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminReportManagement;
  }
}
