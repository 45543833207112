import { API } from "aws-amplify";
import APIUtils from "./../components/APIUtils";
//
export default class APIWrapper {
	constructor(auth) {
		this.auth = auth;
	}
	/** MONTHLY REPORTS **/
	//Fecth MR history of logged advisor, need month and year filter
	async fetchMonthlyReportsApproved(filter, callback) {
		//Build url
		var period = APIUtils.formatStringToGSI1SK(filter.selectedYear,filter.selectedMonth);
		var URL = "/submission?submissionType=MS&status=approved&submissionyearmonth="+period;
		//
		API.get("main", URL, this.getRequestOptions())
					.then(response => {
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
							console.log("success on fetching advisor monthly report history");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on fetching advisor monthly report history");
							callback({error: true, errorData: error, data: null});
					})
	}
	//Fetch all monthly reports with status submitted
	async fetchMonthlyReportsForApproval(callback) {
		API.get("main", "/submission?submissionType=MS&status=submitted", this.getRequestOptions())
					.then(response => {
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
							console.log("success on fetching all monthly reports with status SUBMITTED");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on fetching all monthly reports with status SUBMITTED");
							callback({error: true, errorData: error, data: null});
					})
	}
	//Submit new monthly report for advisor with period and LG entries id
	async submitAdvisorMonthlyReports(period, entries, callback) {
		//Build body
		period = APIUtils.formatToGSI1SK(period, true);
		var body = {"submissionyearmonth" : period, "lgentries" : entries};
		console.log(JSON.stringify(body));
		//
		API.post("main", "/submission" , this.getRequestOptions(body))
					.then(response => {
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
							console.log("success on submitting advisor monthly report");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on submitting advisor monthly report");
							callback({error: true, errorData: error, data: null});
					})
	}
	//Submit MR admin decision of approval or rejection
	async submitMonthlyReportDecision(reportID, isApproved, callback) {
		//Build body
		reportID = reportID.replace("MS::","");
		var body = {"rsaid":reportID, "newStatus": (isApproved ? "approved" : "rejected"), "submissionyearmonth":"201901"};
		console.log(body);
		//
		API.put("main", "/submission", this.getRequestOptions(body))
					.then(response => {
							console.log("success on submitting admin monthly report decision");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on submitting admin monthly report decision");
							callback({error: true, errorData: error, data: null});
					})
	}


	/** LOG ENTRIES **/
		//Fetch all LG of the logged advisor
	async fetchAdvisorMonthlyReportEntriesByPeriod(filter, callback) {
		var period = APIUtils.formatStringToGSI1SK(filter.selectedYear,filter.selectedMonth);
		var URL = "/logEntry?submissionType=MS&submissionyearmonth="+period;
		console.log(URL);
		//keep with call
		API.get("main", URL, this.getRequestOptions())
					.then(response => {
							console.log("success on fetching advisor monthly report LG entries by period");
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on fetching advisor monthly report LG entries by period");
							callback({error: true, errorData: error, data: null});
					})
	}
	//Fetch all log entries of logged advisor with status open
	async fetchLogEntries(callback) {
		//Build URL
		var url = "/logEntry?status=open&rsaid=" + this.auth.sharedCache().getLoggedUserFormattedUserID();
		console.log(url);
		//
		API.get("main", url, this.getRequestOptions())
        	.then(response => {
          		console.log("success on fetching LGs with status OPEN");
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
          		callback({error: false, data: response});
        	})
        	.catch(error => {
          		console.log("fail on fetching LGs with status OPEN");
          		callback({error: true, errorData: error, data: null});
        	})
	}
	//Fetch specific log entry
	async fetchLogEntry(objLG, callback) {
		//Build URL
		var URL = "/logEntry?rsaid=" + objLG["pk"].replace("LG::","") + "&logid=" + objLG["sk"];
		console.log(URL);
		//
		API.get("main", URL, this.getRequestOptions())
        	.then(response => {
          		console.log("success on fetching LG information");
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
          		callback({error: false, data: response});
        	})
        	.catch(error => {
          		console.log("fail on fetching LG information");
          		callback({error: true, errorData: error, data: null});
        	})
	}
	//Fetch all log entries attached to a MR
	async fetchLogEntriesAttachedToMR(objMR, callback) {
		//mount URL
		var url = "/logEntry?rsaid=" + objMR["pk"].replace("MS::","") + "&submissionyearmonth=" + objMR["sk"];
		//keep with call
		API.get("main", url, this.getRequestOptions())
					.then(response => {
							response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
							console.log("success on fetching LGs attached to MR");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on fetching LGs attached to MR" + error);
							callback({error: true, errorData: error, data: null});
					})
	}
	async deleteLogEntry(logEntry, callback) {
		//keep with call
		API.del("main", "/logEntry", this.getRequestOptions(logEntry))
	    	.then(response => {
	      		console.log("success on deleting LG");
	      		callback({error: false, data: response});
	    	})
	    	.catch(error => {
	      		console.log("fail on deleting LG");
	      		callback({error: true, errorData: error, data: null});
	    	})
	}
	async editLogEntry(logEntry, newReceipts, callback) {
		//encode for API friendly format
		if (logEntry.receipts == undefined) logEntry.receipts = [];
		logEntry.receipts = Object.assign(logEntry.receipts, newReceipts);
		var formattedLogEntry = APIUtils.encodeForAPI(logEntry, this.auth.sharedCache());
		console.log("sending EDIT entry with body:" + JSON.stringify(formattedLogEntry));
		//keep with call
		API.put("main", "/logEntry", this.getRequestOptions(logEntry))
        	.then(response => {
          		console.log("success on edit LG");
          		callback({error: false, data: response});
        	})
        	.catch(error => {
          		console.log("fail on edit LG");
          		callback({error: true, errorData: error, data: null});
        	})
	}
	async addLogEntry(logEntry, newReceipts, callback) {
		//console.log("pre" + JSON.stringify(logEntry));
		//encode for API friendly format
		if (logEntry.receipts == undefined) logEntry.receipts = [];
		logEntry.receipts = Object.assign(logEntry.receipts, newReceipts);
		var formattedLogEntry = APIUtils.encodeForAPI(logEntry, this.auth.sharedCache());
		console.log("sending ADD entry with body:" + JSON.stringify(formattedLogEntry));
		//keep with call
		API.post("main", "/logEntry", this.getRequestOptions(formattedLogEntry))
        	.then(response => {
          		console.log("success on adding LG");
          		callback({error: false, data: response});
        	})
        	.catch(error => {
          		console.log("fail on adding LG");
          		callback({error: true, errorData: error, data: null});
        	})
	}


	/** USERS **/
	async getUsers(callback) {
		//keep with call
		API.get("main", "/user", this.getRequestOptions())
					.then(response => {
							console.log("success on retrieving users");
							response = APIUtils.decodeAPIUserData(response);
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on retrieving users");
							callback({error: true, errorData: error, data: null});
					})
	}
	async addUser(user,callback) {
		var formattedUser = APIUtils.encodeForAPI(user, this.auth.sharedCache());
		//keep with call
		API.post("main", "/user", this.getRequestOptions(formattedUser))
					.then(response => {
							console.log("success on adding user");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on adding user");
							callback({error: true, errorData: error, data: null});
					})
	}
	async editUser(user,callback) {
		var formattedUser = APIUtils.encodeForAPI(user, this.auth.sharedCache());
		console.log("sending EDIT user with body:" + JSON.stringify(formattedUser));
		//keep with call
		API.put("main", "/user", this.getRequestOptions(formattedUser))
					.then(response => {
							console.log("success on editing user");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on editing user");
							callback({error: true, errorData: error, data: null});
					})
	}
	async deleteUser(userName,callback) {
		//keep with call
		API.del("main", "/user", this.getRequestOptions({"username": userName}))
					.then(response => {
							console.log("success on deleting user");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on deleting user");
							callback({error: true, errorData: error, data: null});
					})
	}
	async resetUserPassword(userName,callback) {
		//keep with call
		API.put("main", "/user/resetPassword", this.getRequestOptions({"username": userName}))
					.then(response => {
							console.log("success on reseting user password");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on reseting user password");
							callback({error: true, errorData: error, data: null});
					})
	}


	/** SETTINGS **/
	async editSettings(settings,callback) {
		//keep with call
		API.post("main", "/settings", this.getRequestOptions(settings))
					.then(response => {
							console.log("success on editing settings");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on editing settings");
							callback({error: true, errorData: error, data: null});
					})
	}
	async getSettings(callback) {
		//keep with call
		API.get("main", "/settings", this.getRequestOptions())
					.then(response => {
							console.log("success on retrieving settings");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on retrieving settings");
							callback({error: true, errorData: error, data: null});
					})
	}


	/** MONTHLY CATEGORIES **/
	async getCategories(callback) {
		//keep with call
		API.get("main", "/category", this.getRequestOptions())
					.then(response => {
							console.log("success on retrieving categories");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on retrieving categories");
							callback({error: true, errorData: error, data: null});
					})
	}


	/** IMAGE **/
	async uploadImage(file, callback) {
		//encode file name
		var hashedFile = APIUtils.encodeUploadFile(this.auth.sharedCache().getLoggedUserFormattedUserID(), file.name);
		console.log("encoded file name " + hashedFile);
		//request signed url
		this._getSingedURL(API.put, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._uploadToS3(file.file, file.type, resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	async getImage(hashedFile, callback) {
		//request signed url
		this._getSingedURL(API.get, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._getFromS3(resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	async deleteImage(hashedFile, callback) {
		console.log("delete");
		//request signed url
		this._getSingedURL(API.del, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._deleteFromS3(resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	/** IMAGE-S3 **/
	async _getFromS3(url, hashedFile, callback) {
		console.log("Using URL: " + url);
		//keep with call
		fetch(url, {method: "GET"})
					.then(response => {
							console.log("success on getting file");
							callback({error: false, data: response.blob(), hashedFile:hashedFile});
					})
					.catch(error => {
							console.log("fail on getting file");
							callback({error: true, errorData: error, data: null});
					})
	}
	async _deleteFromS3(url, hashedFile, callback) {
		console.log("Using URL: " + url);
		//keep with call
		fetch("https://cors-anywhere.herokuapp.com/" + url, {method: "DELETE"})
					.then(response => {
							console.log("success on deleting file");
							callback({error: false, data: response, hashedFile:hashedFile});
					})
					.catch(error => {
							console.log("fail on deleting file");
							callback({error: true, errorData: error, data: null});
					})
	}
	async _uploadToS3(fileData, fileType, url, hashedFile, callback) {
		//build request
		var opt = { body: fileData,
			 					method: "PUT",
								headers: {
									"Content-type": fileType,
									"Content-length": fileData.length
								}
							};
		//keep with call
		fetch("https://cors-anywhere.herokuapp.com/" + url, opt)
			.then(response => {
					if (!response.ok) throw Error(response.statusText);
					console.log("success on uploading file");
					callback({error: false, data: response, hashedFile: hashedFile});
			}).catch(error => {
					console.log("fail on uploading file");
					callback({error: true, errorData: error, data: null});
			})
	}
	async _getSingedURL(method, hashedFile, callback) {
		//keep with call
		method.bind(API)("main", ("/file/" + hashedFile), this.getRequestOptions())
					.then(response => {
							console.log("success on signing URL for image");
							callback({error: false, data: response, hashedFile: hashedFile});
					})
					.catch(error => {
							console.log("fail on signing URL for image");
							callback({error: true, errorData: error, data: null});
					})
	}




	getRequestOptions(optionalBody, optionalHeader, optionalObj) {
		var value = {
      		headers: {
        		"Authorization": "Bearer " + this.auth.sharedCache().getLoggedUserHash()
						//"App-Agent" : ("RSA-WebApp-" + Utils.getAPPVersion())
      		}
    	}
			//Append optional values
			if (optionalHeader != null) value['headers'] = Object.assign(optionalHeader, value['headers']);
    	if (optionalBody != null) value['body'] = optionalBody;
			if (optionalObj != null) value = Object.assign(value, optionalObj);
    	return value;
	 }
}
