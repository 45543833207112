import React, { Component } from "react";
import { Alert  } from "react-bootstrap";
import autoBind from 'react-autobind';
//
class AlertController extends Component {
  constructor(props) {
    super(props);
    autoBind(this,"handleClose");
    this.lastestTimeout = null;
    this.state = {
      show: false,
      variant: "success",
      title: null,
      description: null
    };
  }
  //Actions
  showErrorAlert(title, description, error) {
    //check for optional error from server
    console.log(error);
    //TODO MAKE CHECK LESS UGLY
    if (error && error["errorData"] && error["errorData"]["response"] && error["errorData"]["response"]["data"] && error["errorData"]["response"]["data"]["message"])
      description += " - " + error["errorData"]["response"]["data"]["message"];
    else if (error && error["errorData"] && error["errorData"]["response"] && error["errorData"]["response"]["data"] && error["errorData"]["response"]["data"]["err"])
      description += " - " + error["errorData"]["response"]["data"]["err"]["message"];
    //set state
    this.setState({
      show: true,
      variant: "danger",
      title: title,
      description: description
    });
    this.lastestTimeout = setTimeout(this.autoDismiss.bind(this),5000);
  }
  showSuccessAlert(title, description) {
    this.setState({
      show: true,
      variant: "primary",
      title: title,
      description: description
    });
    this.lastestTimeout = setTimeout(this.autoDismiss.bind(this),5000);
  }
  showQuestionAlert(title, description) {
    return (window.confirm(title + "\n" + description));
  }


  //Actions
  autoDismiss() {
    this.handleClose();
  }
  handleClose() {
    this.setState({
      show: false, variant: null, title: null, description: null
    });
    clearTimeout(this.lastestTimeout);
    this.lastestTimeout = null;
  }
  //UI
  render() {
    return (<>
              <Alert dismissible className="my-auto" show={this.state.show} variant={this.state.variant} onClose={this.handleClose} >
                <Alert.Heading>{this.state.title}</Alert.Heading>
                  <p>{this.state.description}</p>
              </Alert>
              {React.cloneElement(this.props.children, { alertController: this })}
            </>);
  }
}
//       <Container className="w-75">
      //   <Alert dismissible show={(this.state.secondaryError != null)} variant="danger" className="col-md-10" onClose={this.handleAlertDismiss}>
      //     <Alert.Heading>Error</Alert.Heading>
      //     <pre>{JSON.stringify(this.state.secondaryError)}</pre>
      //   </Alert>
      // </Container>
export default AlertController;
