import React, { Component } from "react";
import ReactTable from "react-table";
import { DropdownButton, Dropdown, Button, Container, Row, Col } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import APIUtils from  "./../components/APIUtils";
import Utils from "./../components/Utils";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import CommonLogFilter from "./subviews/CommonLogFilter";
import CommonLogView from "./subviews/CommonLogView";
import CommonOverlayBox from "./subviews/CommonOverlayBox";
//
import "./../stylesheets/AdvisorReportHistory.css";
//
export default class AdvisorReportHistory extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleRowClick","handleFilterSubmit","fetchData");
    this.state = {error: false, data: [], firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.state.data = response["data"];
      this.state.firstLoad = false;
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.firstLoad = false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchAdvisorMonthlyReportEntriesByPeriod(this.filterObj.getFilters(), response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleFilterSubmit() {
    this.fetchData();
    this.filterObj.disableAction();
  }
  handleRowClick(event, row) {
    this.props.navigationController.pushView(
     <CommonLogView reportLGID={row.original} app={this.props.app}
                    alertController={this.props.alertController}/>
    );
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      minRows: 6,
      noDataText: "No report available!",
      loadingText: "Loading...",
      keyField :'pk',
      loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return {
          onClick: (e) => { this.handleRowClick(e, r) }
        };
      }
    };
    const columns = [
    {
      Header: 'Activity Date',
      accessor: d => (d['activitydate'] ? d['activitydate'].split("-").join("/") : ""),
      id: "activitydate"
    }, {
      Header: 'Activity Description',
      accessor: 'description'
    }, {
      Header: 'Company Name',
      accessor: 'companyname'
    }, {
      Header: 'Total Travel Costs($)',
      accessor: 'travel.totaltravel'
    }, {
      Header: 'Total Consulting Costs($)',
      accessor: 'consulting.consultingpayment'
    }, {
      Header: 'Other Costs($)',
      accessor: 'othercosts'
    }, {
      Header: 'Total Costs($)',
      accessor: 'totalclaim'
    }];
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <CommonLogFilter app={this.props.app}
                       handleFilterSubmit={this.handleFilterSubmit}
                       ref={c => (this.filterObj = c)}/>
      <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props} />
      <CommonOverlayBox className="reportTotalBox">
          <Row className="overlayLabelContainer justify-content-center">
              <Col className="col-md-auto overlayTotalLabel">Grand Total ($)</Col>
              <Col className="col-4-md-auto overlayTotalValue">{APIUtils.getTotalCostFromLGs(data).toFixed(2)}</Col>
          </Row>
      </CommonOverlayBox>
    </div>);
  }
}
//Navigation container implementation
export class AdvisorReportHistoryNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdvisorReportHistory;
  }
}
