import React from "react";
import { Form } from "react-bootstrap";
//
export default class Utils  {
  constructor(props) {}
}
//Data handling helpers
Utils.setNestedObject = function(obj, prop, value) {
  var reference = obj;
  var a = prop.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
      var key = a[i];
      if (i+1 != a.length) { //check if is not last object
        //safe check for sub.object
        if (reference[key] == null) { reference = reference[key] = {}; }
        else reference = reference[key];
      } else { reference[key] = value; }
  }
  return obj;
}
Utils.getNestedObject = function(obj, props) {
  var a = props.split('.');
  if (obj == null) return;
  for (var i = 0, n = a.length; i < n; ++i) {
      var key = a[i];
      if (key in obj) { obj = obj[key]; }
      else { return; }
  } return obj;
}
Utils.safelyGetNumericNestedObject = function(obj, props) {
  var a = props.split('.');
  if (obj == null) return 0;
  for (var i = 0, n = a.length; i < n; ++i) {
      var key = a[i];
      if (key in obj) { obj = obj[key]; }
      else { return 0; }
  } return obj;
}
//UI
Utils.genFormField = function(id, text, enabled, isRequired, type, parent, defaultValue, possibleValues) {
  //safe check
  if (parent == undefined || parent.state == undefined) return (<>Loading</>);
  //
  function safeData() { return parent.state.data === undefined ? {} : parent.state.data; }

  //keep checking foe optional, default and possible values
  var value = Utils.getNestedObject(safeData(),id);
  if (isRequired == null) isRequired = false; //required default to NO
  if (value === undefined || value === null) { //if no value specified
    if (defaultValue !== undefined) { //contains default value
      value = defaultValue;
      //Add into form as default values in case of starting a ADD form
      parent.state.data = Utils.setNestedObject(safeData(),id,value);
    } else value = ''; //no default value and null goes to empty string
  }
  //Check for dropdown render
  if (possibleValues !== undefined) {
    return(<Form.Group controlId={id}>
          <Form.Group>{text}</Form.Group>
          <Form.Control as={type} onChange={parent.handleChange} value={value} name={id}
                        required={isRequired} disabled={!enabled} type={type}>
                        {possibleValues.map(
                          obj => (<option key={obj["pk"]} id={obj["pk"]}>{obj["description"]}</option>),
                        )}
                      </Form.Control>
    </Form.Group>);
  }else { //Formal input render
    return (
      <Form.Group controlId={id}>
            <Form.Group>{text}</Form.Group>
            <Form.Control onChange={parent.handleChange} name={id}
                          required={isRequired} disabled={!enabled}
                          checked={value} type={type} value={value}>
                        </Form.Control>
      </Form.Group>
    );
  }
}
Utils.defaultFormChangeHandler = function(event, object) {
  const targetID = event.target.id;
  var targetValue;
  //Choose where to get value
  if (event.target.type == "checkbox") { targetValue = event.target.checked; }
  else { targetValue = event.target.value; }
  //set state
  object.state.data = Utils.setNestedObject(object.state.data,targetID,targetValue)
  object.setState(object.state.data);
}
Utils.getMonthYearDescriptByDate = function(date) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[date.getMonth()] + "/" + date.getFullYear().toString().slice(2,4);
}
Utils.getMonthName = function(month) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return monthNames[month-1];
}
Utils.setReportDateRange = function(obj) {
  obj.months = [1,2,3,4,5,6,7,8,9,10,11,12];
  //
  var date = new Date();
  obj.years = [];
  for (var i = -1; i < 2; i++) {
    obj.years.push(date.getFullYear() + i);
  }
  //default for filter
  obj.currentYear = obj.years[1];
  obj.currentPreviousMonth = date.getMonth(); //leave as index 0-11
}
