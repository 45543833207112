import APIWrapper from "./APIWrapper";
//
class Cache {
		constructor(props) {
			this.authenticatedUser = null;
			this.userSettings = null;
			this.categories = null
			this.users = null;
		}
		//Custom setters
		setLoggedUser(authenticatedUser) { this.authenticatedUser = authenticatedUser; }
		setSettings(userSettings) { this.userSettings = userSettings; }
		setCategories(categories) { this.categories = categories; }
		setUsers(users) { this.users = users; }
		//Categories Wrappers
		getLGCategories() { return this.categories; }
		getCompanySizes() { return [{"pk":"Small","description":"Small"},{"pk":"Large","description":"Large"}]; }
		//Settings Wrappers
		getSettings() { return this.userSettings; }
		getLoggedAdvisorAnnualMaxExpense() {
			if(this.userSettings && this.userSettings["annualrsamaxexpenses"])
				return parseFloat(this.userSettings["annualrsamaxexpenses"]);
			return 0;
		}
		//User wrapper
		getFormattedUsers() { return this.users; }
		//Get current logged user info
		getLoggerUser() {
			return this._findUserByID(this.getLoggedUserFormattedUserID());
		}
		//JWT Auth token
		getLoggedUserHash() { return this.authenticatedUser.signInUserSession.idToken.jwtToken; }
		//User name string
		getLoggerUserName() {
			if (this.authenticatedUser != null) {
				return this.authenticatedUser["attributes"]["name"];
			} return null;
		}
		//Cognito user ID
		getLoggedUserFormattedUserID() {
			if (this.authenticatedUser != null) {
				return this.authenticatedUser["attributes"]["sub"];
			} return null;
		}
		//Check if any user is logged
		isLogged() { return (this.authenticatedUser != null) }
		//Check if current user is admin
		isLoggedUserAdmin() {
			if (this.authenticatedUser != null) {
				var groups = this.authenticatedUser["signInUserSession"]["accessToken"]["payload"]["cognito:groups"];
				return (groups.includes("Admin"));
			} return false;
		}
		//Search wrappers
		findUserNameByID(userID) {
			for (var obj of this.users) {
				if (obj["id"] == userID) return obj["name"];
			} return "Not found";
		}
		_findUserByID(userID) {
			for (var obj of this.users) {
				if (obj["id"] == userID) return obj;
			} return null;
		}
		//Caching general
		setupUserCache(app,callback) {
			//Settings
			this.fetchSettings(app, usrSettings => { this.setSettings(usrSettings);
				//Categories
				this.fetchCategories(app, categories => {  this.setCategories(categories);
					//Users or user info
					//if not admin this will fetch the current user info only
					this.fetchUsers(app, users => {
						this.setUsers(users);
						callback();
					});
				});
			});
		}
		//Caching calls
		fetchUsers(app, callback) {
			app.sharedAPIWrapper().getUsers(response => {
				if (!response["error"] && response["data"]) callback(response["data"]);
				else return null;
			});
		}
		fetchSettings(app, callback) {
			app.sharedAPIWrapper().getSettings(response => {
				if (!response["error"] && response["data"]) callback(response["data"][0]);
				else return null;
			});
		}
		fetchCategories(app, callback) {
			app.sharedAPIWrapper().getCategories(response => {
				if (!response["error"] && response["data"]) callback(response["data"]);
				else return null;
			});
		}
};

export default Cache;
