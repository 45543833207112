import React, { Component } from "react";
import './../../stylesheets/CommonLoadingView.css';
//
export default class CommonLoadingView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var loading = this.props.isLoading;
    return (
      <div className={(loading ? "" : "hide ") + "loading"}>
        <p className="loading-wheel"></p>
      </div>
    );
    if (loading) {
      return (
        <div className={(loading ? "" : "hide ") + "loading"}>
          <p className="loading-wheel"></p>
        </div>
      );
    }else {
      return (
        <></>
      );
    }
  }
}
