import React, { Component } from "react";
import { Nav, Button } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
//
import AppRoutes from "./AppRoutes";
import "./stylesheets/AppViewController.css";
//
class AppViewController extends Component {

  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }
  //Actions
  handleLogout() {
    this.props.app.setAuthenticatedUser(null);
    this.props.history.push("/");
  }
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    return (
      <div className="App">
        <div className="wrapper">
          {this.getHeader()}
            <div className="main">
              {this.renderUserNavigationBar()}
              <AppRoutes appRef={this}/>
            </div>
          </div>
      </div>
    );
  }
  getHeader() {
    return (<header className="header d-flex align-content-center justify-content-end">
              <h1 className="mr-auto logo"></h1>
              {(this.props.app.sharedCache().isLogged() ?
                <h4 className="my-auto headerUserName ">{this.props.app.sharedCache().getLoggerUserName()}</h4>
              : <></>)}
            </header>);
  }
  renderUserNavigationBar() {
    if (!this.props.app.sharedCache().isLogged()) {
      return(<></>);
    }else {
      return (
                <Nav defaultActiveKey="/admUsers" className="flex-column nav navbar">
                  <h3>Menu</h3>
                  {(this.props.app.sharedCache().isLoggedUserAdmin() ?
                    <>
                      <IndexLinkContainer to="/"><Nav.Item>Monthly Reports</Nav.Item></IndexLinkContainer>
                      <LinkContainer to="/admReportHistory"><Nav.Item>Advisor History</Nav.Item></LinkContainer>
                      <LinkContainer to="/4"><Nav.Item>Quarterly Report</Nav.Item></LinkContainer>
                      <LinkContainer to="/5"><Nav.Item>Yearly Summary</Nav.Item></LinkContainer>
                      <LinkContainer to="/admUsers"><Nav.Item>Users</Nav.Item></LinkContainer>
                      <LinkContainer to="/admSettings"><Nav.Item>System Settings</Nav.Item></LinkContainer>
                    </>
                    :
                    <>
                      <IndexLinkContainer to="/"><Nav.Item>Activity Report</Nav.Item></IndexLinkContainer>
                      <LinkContainer to="/advReportHistory"><Nav.Item>Monthly Report</Nav.Item></LinkContainer>
                      <LinkContainer to="/advQR"><Nav.Item>Quarterly Report</Nav.Item></LinkContainer>
                    </>)}
                  <span className="nav-span"></span>
                  <Button variant="danger" className="navLogoutButton" onClick={this.handleLogout}><Nav.Item>Logout</Nav.Item></Button>
                </Nav>);
    }
  }
}
export default AppViewController;
