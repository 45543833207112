import { Component } from "react";
import { Auth } from "aws-amplify";
import APIWrapper from "./APIWrapper";
import Cache from "./Cache";
//
class Authenticator extends Component {
		constructor(props) {
			super(props);
			this.state = {isAuthenticating: false};
			this._sharedCache = null;
			this._sharedAPI = null;
		}
	  async componentDidMount() {
	  	if (!this.sharedCache().isLogged()) this.checkForAuthetication();
		}

		//STATES
		setAuthenticatedUser(user) {
			if (user == null) { //logout
				Auth.signOut();
				this.sharedCache().setLoggedUser(null);
				this.endLoading();
			}else { //login
				this.checkForAuthetication();
			}
		}
		//Loading
		startLoading() {
			var isAuthenticating = true;
			this.setState({isAuthenticating});
		}
		//end callback
		endLoading(errored) {
			var isAuthenticating = false;
			this.setState({isAuthenticating});
			if (errored) this.sharedCache().setLoggedUser(null);
		}
		//Singleton
		sharedAPIWrapper() {
	      if (this._sharedAPI == null) { this._sharedAPI = new APIWrapper(this); }
	      return this._sharedAPI;
	  }
		//Singleton
		sharedCache() {
	      if (this._sharedCache == null) { this._sharedCache = new Cache(); }
	      return this._sharedCache;
	  }
  	//COGNITO
		checkForAuthetication() {
			this.startLoading();
			Auth.currentSession().then(data => {
			    // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user decipher.setAuthTag(buffer)
					/*When using Authentication with AWS Amplify, you don’t need to refresh Amazon Cognito tokens manually. The tokens are automatically refreshed by the library when necessary.*/
					// This will refresh token every new login user makes it
			    Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
								this.sharedCache().setLoggedUser(user);
								this.sharedCache().setupUserCache(this, end => this.endLoading(false));
							})
			        .catch(err => {
								console.log("user not autheticated " + err);
								this.endLoading(true);
							})
			  	})
			  .catch(err => {
			    console.log("user not autheticated " + err);
					this.endLoading(true);
			  });
		}
};

export default Authenticator;
