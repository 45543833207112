import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
//Compoennts
import App from "./app/App";
//
import './app/stylesheets/index.css';
//DEVEL
import * as serviceWorker from './serviceWorker';
//Render router with App
ReactDOM.render(
  <Router>
  	<App/>
  </Router>,
  document.getElementById("root")
);
//DEVEL
serviceWorker.unregister();
