import React, { Component } from "react";
import ReactTable from "react-table";
import { Container, Row, Col, Button } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import APIUtils from  "./../../components/APIUtils";
import Utils from "./../../components/Utils";
import CustomComponent from "./../../components/CustomComponent";
import CommonLogView from "./CommonLogView";
import CommonLoadingView from "./CommonLoadingView";
import CommonOverlayBox from "./../subviews/CommonOverlayBox";
//
import "./../../stylesheets/CommonLogTable.css";
//
export default class CommonLogTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this, "handleRowClick", "handleBackButton", "fetchData");
    this.state = { error: false, data: [], firstLoad: true };
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.state.data = response['data'];
      this.state.firstLoad =  false;
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.firstLoad =  false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleBackButton() {
    this.props.navigationController.popView();
  }
  handleRowClick(event, row) {
    this.props.navigationController.pushView(
      <CommonLogView reportLGID={row.original} app={this.props.app}
                     alertController={this.props.alertController}/>
    );
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchLogEntriesAttachedToMR(this.props.reportMR,response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      noDataText: "No data!",
      loadingText: "Loading...",
      keyField :'sk',
      loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return {
          onClick: (e) => { this.handleRowClick(e, r) }
        };
      }
    };
    const columns = [
    {
      Header: 'Activity Date',
      accessor: 'activitydate',
      id: "activitydate"
    }, {
      Header: 'Activity Description',
      accessor: 'description'
    }, {
      Header: 'Company Name',
      accessor: 'companyname'
    }, {
      Header: 'Total Travel Costs($)',
      accessor: 'travel.totaltravel',
      Cell: props => <span className='someClasss'>{props.value}</span>
    }, {
      Header: 'Total Consulting Costs($)',
      accessor: 'consulting.consultingpayment',
      Cell: props => <span className='someClasss'>{props.value}</span>
    }, {
      Header: 'Other Costs($)',
      accessor: 'othercosts',
      Cell: props => <span className='someClasss'>{props.value}</span>
    }, {
      Header: 'Total Costs($)',
      accessor: 'totalclaim',
      Cell: props => <span className='someClasss'>{props.value}</span>
    }];
    //render
    return (<div className="AppPageContainer">
              <CommonLoadingView isLoading={this.state.isLoading}/>
              <Button variant="danger" onClick={this.handleBackButton}> Back </Button>
              <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props}/>
              <CommonOverlayBox className="reportTotalBox">
                  <Row className="overlayLabelContainer justify-content-center">
                      <Col className="col-md-auto overlayTotalLabel">Grand Total ($)</Col>
                      <Col className="col-4-md-auto overlayTotalValue">{APIUtils.getTotalCostFromLGs(data).toFixed(2)}</Col>
                  </Row>
              </CommonOverlayBox>
           </div>);
  }
}
