import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomRoute from "./components/CustomRoute";
//views
import {AdvisorLogNavigationController} from "./views/AdvisorLog";
import {AdminUserManagerNavigationController} from "./views/AdminUserManager";
import {AdminSettingsNavigationController} from "./views/AdminSettings";
import {AdminReportManagementNavigationController} from "./views/AdminReportManagement";
import {AdminReportHistoryNavigationController} from "./views/AdminReportHistory";
import {AdvisorReportHistoryNavigationController} from "./views/AdvisorReportHistory";
import Error from "./views/error";
import CommonLogin from "./views/CommonLogin";
import CommonResetPassword from "./views/CommonResetPassword";

export default ({ appRef }) =>
  <Switch>
  	{appRef.props.app.sharedCache().isLogged() ?
    <>
      {appRef.props.app.sharedCache().isLoggedUserAdmin() ?
      <>
        <CustomRoute path="/admUsers" exact component={AdminUserManagerNavigationController} appRef={appRef} />
        <CustomRoute path="/admSettings" exact component={AdminSettingsNavigationController} appRef={appRef} />
        <CustomRoute path="/" exact component={AdminReportManagementNavigationController} appRef={appRef} />
        <CustomRoute path="/admReportHistory" exact component={AdminReportHistoryNavigationController} appRef={appRef} />
      </> :
      <>
        <CustomRoute path="/advReportHistory" exact component={AdvisorReportHistoryNavigationController} appRef={appRef} />
        <CustomRoute path="/" exact component={AdvisorLogNavigationController} appRef={appRef}/>
      </>}
    </> :
    <>
      <CustomRoute path="/" exact component={CommonLogin} appRef={appRef}/>
      <CustomRoute path="/resetPassword" exact component={CommonResetPassword} appRef={appRef}/>
    </>}
    <Route component={Error} />
  </Switch>;
