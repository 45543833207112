export default {
  amplify: {
    Auth: {
      mandatorySignIn: false,
      region: "ca-central-1",
      userPoolId: "ca-central-1_n21iH4GNP",
      identityPoolId: "mark",
      userPoolWebClientId: "1dr3o2j3nok6ae1a0ejc88c31s"
    },
    Storage: {
      region: "ca-central-1",
      bucket: "rsa-rasdev-canada-receiptstore",
      identityPoolId: "ca-central-1_n21iH4GNP"
    },
    API: {
      endpoints: [
        {
          name: "main",
          endpoint: "https://n60rtiloak.execute-api.ca-central-1.amazonaws.com/rasdev_canada",
          region: "ca-central-1"
        },
      ]
    }
  }
};
