import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import NavigationController from 'react-navigation-controller';
import autoBind from 'react-autobind';
import DatePicker from "react-datepicker";
//
import APIUtils from "./../components/APIUtils";
import Utils from "./../components/Utils";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import CommonOverlayBox from "./subviews/CommonOverlayBox";
import LogForm from "./subviews/AdvisorLogForm";
import LogGauge from "./subviews/AdvisorLogGauge";
import LogTable from "./subviews/AdvisorLogTable.js";
//
import "./../stylesheets/AdvisorLog.css";
import "react-datepicker/dist/react-datepicker.css";
//
export default class AdvisorLog extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleAddLog","handleSubmitReport","handleSubmitPeriod","handleTableDeleteUpdate","handleTableSelectionUpdate", "fetchData");
    this.state = { error: false, data: [], firstLoad: true, currentSubmitPeriod: new Date(), isLoading: false };
  }
  //states
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
    this.state.data = response["data"];
    this.state.firstLoad = false;
    this.tableRef.selectAllRows(response['data']);
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.firstLoad = false;
    } this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchLogEntries(response => {
      if (!this._isMounted) return;
      this.stopLoading();
      this.loadResponse(response);
    });
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
  handleSubmitReport() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().submitAdvisorMonthlyReports(this.state.currentSubmitPeriod,this.tableRef.getSelectedIDS(), response => {
      if (!this._isMounted) return;
      if (!response['error']) {
        this.props.alertController.showSuccessAlert("Submited!", "Report submitted with success!");
        this.fetchData();
      }else {
        this.props.alertController.showErrorAlert("Error", JSON.stringify(response["errorData"]));
        this.stopLoading();
      }
    });
  }
  handleSubmitPeriod(e) {
    this.state.currentSubmitPeriod = e;
    this.setState(this.state.currentSubmitPeriod);
  }
  handleAddLog() {
    this.props.navigationController.pushView(
      <LogForm actionType="ADD" app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  //Table handler
  handleTableDeleteUpdate() { this.fetchData(); } //this call will be made by table to request DATA update
  handleTableSelectionUpdate() { this.forceUpdate(); } //this call will be made by table to request UI update because of seleciton change
  //UI
  render() {
    //check if have any selected row, if not, disable submission button
    var disableSubmission = (this.tableRef === undefined ? true : (this.tableRef.getSelectedIDS().length == 0));
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <LogGauge percentage={APIUtils.getAdvisorExpensesPercentageFromLGs(this.state.data, this.props.app.sharedCache())}>
        <div className="addLogActionContainer">
          <Button className="addLogButton auto" variant="none" onClick={this.handleAddLog}>╋</Button>
          <div className="addLogButtonText">New</div>
        </div>
      </LogGauge>
      <LogTable data={this.state.data} uiUpdateHandler={this.handleTableSelectionUpdate}
                deletedUpdateHandler={this.handleTableDeleteUpdate}
                ref={c => (this.tableRef = c)} app={this.props.app}
                alertController={this.props.alertController}
                navigationController={this.props.navigationController}/>
      <CommonOverlayBox className="logSubmissionBox">
        <Row className="logBoxGrandTotalContainer">
          <Col className="col-md-auto logBoxGrandTotalLabel"> Grand Total ($) </Col>
          <Col className="col-md-auto logBoxGrandTotalPriceLabel"> {APIUtils.getTotalCostFromLGs(this.state.data).toFixed(2)} </Col>
        </Row>
        <Row className="logBoxDateContainer">
          <Col className="col logBoxSubmissionLabel">Submitting for:</Col>
          <Col className="col-md-auto logBoxSubmissionDateLabel">
            <DatePicker popperPlacement="top" customInput={
                <Button variant="none logBoxSubmissionDateButton">
                  {Utils.getMonthYearDescriptByDate(this.state.currentSubmitPeriod) + " ≣"}
                </Button>
              } selected={this.state.currentSubmitPeriod}
              onMonthChange={this.handleSubmitPeriod}
              onYearChange={this.handleSubmitPeriod}
              showMonthDropdown showYearDropdown dropdownMode="select"
              renderDayContents={function(){}} />
          </Col>
        </Row>
        <Row className="logBoxButtonContainer">
          <Col className="col-md-auto justify-content-center mx-auto">
            <Button variant="none" className="logBoxSubmissionButton" disabled={disableSubmission}
                    onClick={this.handleSubmitReport}>Submit Report</Button>
          </Col>
        </Row>
    </CommonOverlayBox>
    </div>);
  }
}
//Navigation container implementation
export class AdvisorLogNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdvisorLog;
  }
}
