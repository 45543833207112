import React, { Component } from "react";
//
class CustomComponent extends Component {
  constructor(props) {
    super(props);
    this._isMounted = true;
  }
  componentDidMount() { this._isMounted = true; }
  componentWillUnmount() { this._isMounted = false; }
}
export default CustomComponent;
