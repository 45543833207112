import React, { Component } from "react";
import { Button, Tab, Tabs, Col, Row, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
//
import autoBind from 'react-autobind';
import Utils from "./../../components/Utils";

//
export default class AdvisorLogFormAsyncImage extends Component {
  constructor(props) {
    super(props);
    this.state = {isLoading: true, hashedFileName: this.props.fileName, fileData: null};
    autoBind(this);
    this.startLoading();
  }
  //
  startLoading() {
    this.props.app.sharedAPIWrapper().getImage(this.state.hashedFileName, object => {
      if (object.data == null) return;
      object.data.then( blobData => {
        this._loadBlob(blobData);
      });
    })
  }
  _loadBlob(blobData){
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
      //
      this.state.fileData = reader.result;
      this.state.isLoading = false;
      this.setState(this.state);
    }.bind(this), false);
    reader.readAsDataURL(blobData);
  }
  //
  render() {
    return(
      <div key={this.state.hashedFileName} className="imageContainer">
        <img className="imageObject" src={this.state.fileData}/>
        {this.state.isLoading ?
          <FontAwesomeIcon className="loadingIcon" icon={faSpinner} spin size="6x"/>
            :
          <FontAwesomeIcon className="imageDeleteButton" id={this.state.hashedFileName} icon={faMinusCircle} size="4x" onClick={this.props.dataSource.handleImageDelete}/>
        }
      </div>);
  }
}
