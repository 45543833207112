import React from "react";
import Amplify from "aws-amplify";
import { withRouter } from "react-router-dom";
//
import AppViewController from "./AppViewController";
import AlertController from "./components/AlertController";
import Authenticator from "./components/Authenticator";
//
import config from "./config/config";
//Start amplify
Amplify.configure(config["amplify"]);
//
class App extends Authenticator {
  constructor(props) {
    super(props);
  }
  //UI
  render() {
    return (
      <AlertController>
        <AppViewController app={this} history={this.props.history} logoutHandler={this.handleLogout}>
        </AppViewController>
      </AlertController>);
  }
}
export default withRouter(App);
