import React, { Component } from "react";
import { Button, Tab, Tabs, Col, Row } from "react-bootstrap";
//
import Utils from "./../../components/Utils";
import AdvisorLogFormImageGallery from "./AdvisorLogFormImageGallery";
//
export default class AdvisorLogFormChildren extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedTab: 1};
  }
  //UI
  render() {
    var editMode = !this.props.viewOnly;
    return (
      <Tabs activeKey={this.state.selectedTab} className="log-nav-item" onSelect={this.props.dataSource.handleTabChange}>
        {this.returnGeneralView(editMode)}
        {this.returnStatsView(editMode)}
        {this.returnUploadView(editMode)}
      </Tabs>
    );
  }
  returnGeneralView(editMode) {
    return (
      <Tab className="childBox child1" eventKey="1" title="General">
        <Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("activitydate", "Activity Date", editMode, true, "date", this.props.dataSource, "2020-02-02")}</h6></Col>
          <Col className="col-2 offset-2"><h6>{Utils.genFormField("ctid", "Category", editMode, false, "select", this.props.dataSource, "None", this.props.app.sharedCache().getLGCategories())}</h6></Col>
          <Col className="col-2 offset-2"><h5 className="logLeftMarginLabel">Contact Type</h5></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-3"><h6>{Utils.genFormField("description", "Activity Description", editMode, true, "textarea", this.props.dataSource, "description")}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("wcdid", "WCB ID", editMode, false, "text", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("contacttype.numinperson", "In-person", editMode, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("companyname", "Company name", editMode, true, "text", this.props.dataSource, "Digital One")}</h6></Col>
          <Col className="col-2 offset-2"><h6>{Utils.genFormField("newtocor", "New to COR?", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("contacttype.numemail", "E-mail", editMode, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("companysize", "Company Size", editMode, true, "select", this.props.dataSource, "Small", this.props.app.sharedCache().getCompanySizes())}</h6></Col>
          <Col className="col-2 offset-2"><h6>{Utils.genFormField("corcertified", "COR Certified?", editMode, true, "checkbox", this.props.dataSource, true)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("contacttype.numphone", "Phone", editMode, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("repname", "Company Representative's name", editMode, false, "text", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("cu", "Classification Unit", editMode, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-3"><h6>{Utils.genFormField("contacttype.numtext", "Text", editMode, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("repemail", "Company Representative’s email", editMode, false, "text", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("repphone", "Company Representative’s Phone Number", editMode, false, "text", this.props.dataSource)}</h6></Col>
        </Row>
      </Tab>
    );
  }
  returnStatsView(editMode) {
    return (
      <Tab className="childBox child2" eventKey="2" title="Stats">
        <Row className="justify-content-start">
          <Col className="col-2"><h5>Activity Info</h5></Col>
          <Col className="col-3 offset-1"><h5>High Risk Startegy</h5></Col>
          <Col className="col-2 offset-1"><h5>Finances</h5></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("numattendees", "Number of Attendees", editMode, true, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("highrisk.generalpresentations", "General presentation", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("consulting.consultinghours", "Consulting Hours", editMode, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("consulting.consultingpayment", "Consulting Costs ($)", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("consulting.consultinggst", "Consulting GST ($)", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("consulting.consultingaftertax", "Total Consulting Costs ($) Payment:", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("gapanalysis", "GAP Analysis?", editMode, false, "checkbox", this.props.dataSource, true)}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("highrisk.falls", "Falls", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("travel.km", "KM traveled", editMode, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("travel.kmreimbursement", "KM reimbursement", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("travel.kmgst", "KM GST", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("iminquiry", "IM Inquiry?", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("highrisk.scaffolding", "Scaffolding", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("travel.traveltime", "Travel time", editMode, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1 "><h6>{Utils.genFormField("travel.travelreimbursement", "value travel time before taxes", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1 "><h6>{Utils.genFormField("travel.traveltimegst", "gst over travelreimbursement amount", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2 "><h6>{Utils.genFormField("socialmediasubmission", "Social Media Submission", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("highrisk.workplatforms", "Work Platforms", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("travel.totaltravel", "Travel Costs ($)", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1 "><h6>{Utils.genFormField("travel.totaltravelgst", "Travel GST ($)", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1 "><h6>{Utils.genFormField("travel.totaltravel", "Total Travel Costs ($)", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row><Row className="justify-content-start">
          <Col className="col-2"><h6>{Utils.genFormField("safetyclimatetool", "Safety Climate Tool Inquires/Presentations", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-2 offset-1"><h6>{Utils.genFormField("highrisk.mobileequip", "Mobile Equipment", editMode, false, "checkbox", this.props.dataSource)}</h6></Col>
          <Col className="col-1 offset-2"><h6>{Utils.genFormField("othercosts", "Other Costs ($)", editMode, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("totalclaim", "Total Costs($)", false, false, "number", this.props.dataSource)}</h6></Col>
          <Col className="col-1"><h6>{Utils.genFormField("totalclaimgst", "Total Cost GST($)", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row>
        <Row>
          <Col className="col-1 offset-10"><h6>{Utils.genFormField("totalclaimaftertax", "Grand Total ($)", false, false, "number", this.props.dataSource)}</h6></Col>
        </Row>
      </Tab>
    );
  }
  returnUploadView(editMode) {
    var savedReceipts = (this.props.dataSource.state.data.receipts != undefined ? this.props.dataSource.state.data.receipts : []);
    var unsavedReceipts = (this.props.dataSource.state.pendingImages != undefined && this.props.dataSource.state.pendingImages.length > 0);
    return (
      <Tab className="childBox child3" eventKey="3" title="Files">
        <AdvisorLogFormImageGallery editMode={editMode} savedReceipts={this.props.dataSource.state.unsavedReceipts} dataSource={this.props.dataSource} app={this.props.dataSource.props.app}/>
      </Tab>
    );
  }
}
