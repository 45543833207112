import React, { Component } from "react";
import { Button, Container, Col, Row, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import "./../../stylesheets/CommonLogFilter.css"
import Utils from "./../../components/Utils";
//
export default class CommonLogFilter extends Component {
  constructor(props) {
    super(props);
    //setup
    autoBind(this,"handleChange");
    this.isAdmin = this.props.app.sharedCache().isLoggedUserAdmin();
    this.buildFilter();
  }
  //helper
  buildFilter() {
    var yms = {};
    Utils.setReportDateRange(yms);
    var newState = {
        selectedYear: yms.currentYear,
        selectedMonth: yms.currentPreviousMonth,
        selectedUser: "",
        years: yms.years,
        months: yms.months,
        hasChanged: false
      };
      if (this.isAdmin) newState.users = this.props.app.sharedCache().getFormattedUsers();
      this.state = newState;
  }
  //Public
  disableAction() {
    this.state.hasChanged = false;
    this.setState(this.state);
  }
  getFilters() {
    return {
      selectedUser:this.state.selectedUser,
      selectedMonth: this.state.selectedMonth,
      selectedYear: this.state.selectedYear
    };
  }
  //Actions
  handleChange(value, event) {
    const targetID = event.target.name;
    if (targetID == "user") { this.state.selectedUser = value; }
    else if (targetID == "year") { this.state.selectedYear = value; }
    else { this.state.selectedMonth = value; }
    //let go button be activate
    this.state.hasChanged = true;
    this.setState(this.state);
  }
  //UI
  render() {
    //render
    return (<Container className="filterContainer">
              <Row className="selectionBar">
                <Col>
                  <ButtonToolbar>
                    <ToggleButtonGroup onChange={this.handleChange} type="radio" name="year" defaultValue={this.state.selectedYear}>
                      {this.state.years.map(
                        obj => {
                          return (<ToggleButton className="yearButton" key={obj} value={obj}>{obj}</ToggleButton>)
                        }
                      )}
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </Col>
                <Col>
                  <Button disabled={!this.state.hasChanged} className="goButton" onClick={this.props.handleFilterSubmit} variant="success">Go!</Button>
                </Col>
              </Row>
              <Row className="selectionBar">
                <Col>
                  <ButtonToolbar>
                    <ToggleButtonGroup onChange={this.handleChange} type="radio" name="month" defaultValue={this.state.selectedMonth}>
                      {this.state.months.map(
                        obj => {
                          return (<ToggleButton className="monthButton" key={obj} value={obj}>{Utils.getMonthName(obj)}</ToggleButton>)
                        }
                      )}
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
                {/*this.isAdmin*/false ?
              <Row className="selectionBar">
                  <Col>
                    <ButtonToolbar>
                          <ToggleButtonGroup onChange={this.handleChange} type="radio" name="user" defaultValue={this.state.selectedUser}>
                        {this.state.users.map(
                          obj => {
                            var username = obj["username"];
                            return (<ToggleButton key={username} value={username}>{obj["name"]}</ToggleButton>)
                          }
                        )}
                        <ToggleButton key="" value="">None</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row> :
                <></>}
           </Container>);
  }
}
