import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import Utils from "./../../components/Utils";
//
export default class AdminUserDetails extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleChange","handleSubmit","handleCancel");
    this.setInitialState();
  }
  setInitialState() {
    if (this.props.userData !== undefined)
      this.state = {data: this.props.userData};
    else
      this.state = {data: {}};
  }
  //Actions
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  handleCancel() { this.props.navigationController.popView(); }
  handleSubmit(e) {
    e.preventDefault(); //prevent form submission
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to add this user? ")) return;
    //action
    this._submitUser();
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //UI
  render() {
    var isAdmin = this.state.data.type;
    var isAdding = (this.props.actionType == "ADD");
    return (
      <div className="AppPageContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Form className="userDetailsForm" onSubmit={this.handleSubmit}>
        <Container>
            <Row className="justify-content-start">
              <Col className="col-2"><h4>{Utils.genFormField('type',"Is admin:", isAdding, false, "checkbox", this, false)}</h4></Col>
              <Col className="col-3 offset-4"><h4>{Utils.genFormField('username',"Username:", isAdding, true, "text", this)}</h4></Col>
            </Row><Row className="justify-content-start">
              <Col className="col-3"><h4>{Utils.genFormField('name',"Name:", true, true, "text", this)}</h4></Col>
              <Col className="col-3 offset-3"><h4>{Utils.genFormField('email',"Email:", true, true, "text", this)}</h4></Col>
            </Row><Row className="justify-content-start">
              <Col className="col-2"><h4>{Utils.genFormField('gstnumber',"GST number:", !isAdmin, true, "text", this)}</h4></Col>
              <Col className="col-2 offset-4"><h4>{Utils.genFormField('timepaidshortdistance',"Short distance % of payment:", !isAdmin, true, "number", this)}</h4></Col>
            </Row><Row className="justify-content-start">
              <Col className="col-2"><h4>{Utils.genFormField('businessname',"Bussiness name:", !isAdmin, true, "text", this)}</h4></Col>
              <Col className="col-2 offset-4"><h4>{Utils.genFormField('hourlyrate',"Hourly rate:", !isAdmin, true, "number", this)}</h4></Col>
            </Row><Row className="justify-content-start">
              <Col className="col-4"><h4>{this.renderPasswordField()}</h4></Col>
            </Row>
            <Row  className="justify-content-center">
              <Col className="md-auto">
                <Button className="cancelUserButton" variant="danger" onClick={this.handleCancel}>Cancel</Button>
              </Col>
              <Col className="md-auto">
                <Button className="saveUserButton" variant="none" type="submit">{(isAdding ? "Create" : "Save")}</Button>
              </Col>
            </Row>
        </Container>
      </Form>
    </div>
    );
  }
  renderPasswordField() {
    if (this.props.actionType == "ADD") {
      return Utils.genFormField('password',"Password:", true, true, "text", this);
    }else return (<></>);
  }




  //private
  _submitUser() {
    const sendData = this.state.data;
    this.startLoading();
    ////ADD USER API CALL
    if (this.props.actionType == "ADD") {
      this.props.app.sharedAPIWrapper().addUser(sendData ,response => {
        if (!this._isMounted) return;
        if (!response["error"]) {
          this.props.alertController.showSuccessAlert("Success","User created with success!");
          this.props.navigationController.popView();
        } else {
          this.props.alertController.showErrorAlert("Error","Error while creating user.",response);
        } this.stopLoading();
      });
      //EDIT USER API CALL
    } else if (this.props.actionType == "EDIT") {
      this.props.app.sharedAPIWrapper().editUser(sendData ,response => {
        if (!this._isMounted) return;
        if (!response["error"]) {
          this.props.alertController.showSuccessAlert("Success","User edited with success!");
          this.props.navigationController.popView();
        } else {
          this.props.alertController.showErrorAlert("Error","Error while editing user.",response);
        } this.stopLoading();
      });
    }
  }
}
