import React, { Component } from "react";
import { Nav, Button, Form, Container, Col, Row } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import AdvisorLogFormChildrenController from "./AdvisorLogFormChildrenController";
import Utils from "./../../components/Utils";
//
import "./../../stylesheets/AdvisorLogForm.css";
//
export default class AdvisorLogForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //Safe check for Add action with null props.data
    var data = (this.props.data == null ? {} : this.props.data);
    this.state = { data, selectedTab: 0, pendingDeleteImages: [] /*images not uploaded*/, unsavedReceipts: [] /*images uploaded but not saved*/ };
  }
  loadData(newData) {
    this.state = { data: newData, selectedTab: 0, pendingDeleteImages: [] /*images not uploaded*/, unsavedReceipts: [] /*images uploaded but not saved*/ };
    this.setState(this.state);
  }
  //Utils
  startLoading() {
    this.state.isLoading = true;
    this.setState(this.state);
  }
  stopLoading() {
    this.state.isLoading = false;
    this.setState(this.state);
  }
  //Actions
    //Tab actions
  handleTabChange(idx) {
    this.state.selectedTab = idx;
    this.setState(this.state);
    this.childrenController.setState({ selectedTab: this.state.selectedTab });
  }
    //Image gallery actions
  handleImageDelete(event) { //handle specific image delete
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to delete this receipt? ")) return;

    //mark image for deletion
    var fileName = event.target.id;
    this.state.pendingDeleteImages.push(fileName);
    //try to remove from receipts
    if (this.state.data.receipts != undefined)
      this.state.data.receipts.splice(this.state.data.receipts.indexOf(fileName),1);
    //try to remove from unsaved receipts
    if (this.state.unsavedReceipts != undefined)
      this.state.unsavedReceipts.splice(this.state.unsavedReceipts.indexOf(fileName),1);
    this.setState(this.state);
  }
  handleImageUpload(file) { this._uploadImage(file); }
  //Form actions
  handleChange(event) {
    this._calculateOtherValues();
    Utils.defaultFormChangeHandler(event, this);
  }
  handleValidation() { return this._validateForm(); }
    //Navigation actions
  handleCancel() {
    //confirmation ask
    if (this.props.actionType != "VIEW")
      if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to discard the changes you made?")) return;
    //Action
    this._discardForm(); //discard pending images
    this.props.navigationController.popView();
  }
  handleSubmit(e) {
    e.preventDefault(); //prevent submission call from form
    if (!this.handleValidation(e)) return;
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to save the changes?")) return;
    //actions
    this._submitLog();
  }

  //UI
  render() {
    var safeReceipts = (this.state.data.receipts == undefined ? [] : this.state.data.receipts);
    return (
      <div className="logContainer h-100">
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Form ref={f => (this.formEl = f)} onInvalid={this.handleValidation} onSubmit={this.handleSubmit}>
          <Container className="logFormContainer">
            <h6 className="formLabel">{this.getModalActionTypeText()}</h6>
            <AdvisorLogFormChildrenController viewOnly={(this.props.actionType == "VIEW")} ref={c => (this.childrenController = c)} dataSource={this} app={this.props.app}/>
            {this.getActionButtons()}
          </Container>
        </Form>
      </div>
    );
  }

  //Utils
  getModalActionTypeText() {
    if (this.props.actionType == "ADD") return "Add Log";
    else if (this.props.actionType == "EDIT") return "Edit Log";
    else if (this.props.actionType == "VIEW") return "View Mode";
    else return "UNKNOWN";
  }
  getSubmitButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Create";
    else if (this.props.actionType == "EDIT") return "Save";
    else if (this.props.actionType == "VIEW") return "N/A";
    else return "UNKNOWN";
  }
  getCancelButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Cancel";
    else if (this.props.actionType == "EDIT") return "Cancel";
    else if (this.props.actionType == "VIEW") return "Back";
    else return "UNKNOWN";
  }
  getActionButtons() {
    return (
      <Row>
        <Col> <Button variant="danger" onClick={this.handleCancel}>{this.getCancelButtonTitleTypeText()}</Button> </Col>
        <Col>
          { this.props.actionType == "VIEW" ? <></> :
              <Button variant="primary" type="submit">{this.getSubmitButtonTitleTypeText()}</Button>
          }
        </Col>
      </Row>
    );
  }

  //private
  _submitLog() {
    const sendData = this.state.data;
    //Choose call
    var APICall = null;
    if (this.props.actionType == "ADD") { //ADD LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().addLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else if (this.props.actionType == "EDIT") { //EDIT LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().editLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else console.log("unknown submit action on log modal!");
    //Make the actual call
    this.startLoading();
    APICall(sendData, this.state.unsavedReceipts ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Log entry added with success!");
        this.props.navigationController.popView();
      } else {
        this.props.alertController.showErrorAlert("Error","Error while adding log entry.",response);
      }
      this._removeFlaggedImages(); //keep with removal of flagged images from S3
      this.stopLoading();
    });
  }
  //Images
  _uploadImage(file) {
    this.startLoading();
    this.props.app.sharedAPIWrapper().uploadImage(file, resp => {
      if (!this._isMounted) return;
      if (!resp["error"]) {
        this.props.alertController.showSuccessAlert("Success","Receipt uploaded with success!");
        this.state.unsavedReceipts.push(resp.hashedFile);
        this.setState(this.state);
      } else {
        this.props.alertController.showErrorAlert("Error","Error while uploading receipt.",resp);
      } this.stopLoading();
    });
  }
  _deleteImage(imageName) {
    this.startLoading();
    //Try to removeImage
    this.props.app.sharedAPIWrapper().deleteImage(imageName, resp => {
      if (!this._isMounted) return;
      if (!resp["error"]) {
        //for each image add into receipts array and pendingImages[]
        for (var fileIdx in this.state.data.receipts) {
          var img = this.state.data.receipts[fileIdx];
          if (img == imageName) this.state.data.receipts.splice(fileIdx,1);
        }
        for (var fileIdx in this.state.unsavedReceipts) {
          var img = this.state.unsavedReceipts[fileIdx];
          if (img == imageName) { this.state.unsavedReceipts.splice(fileIdx,1); }
        }
        this.props.alertController.showSuccessAlert("Success","Image deleted with success!");
        this.setState(this.state);
      } else {
        this.props.alertController.showErrorAlert("Error","Error while deleting image.",resp);
      }
    });
  }
  //Image control
  _discardForm() {
    this.state.unsavedReceipts.forEach((imageName, idx, array) => {
      this.props.app.sharedAPIWrapper().deleteImage(imageName, function(){
        //if (idx === array.length - 1) this.stopLoading(); -- DO NOT STATUS UPDATE SINCE ITS EXECUTED WHEN PAGE IS GOING OUT
      });
    });
  }
  _removeFlaggedImages() {
    this.state.pendingDeleteImages.forEach((imageName, idx, array) => {
      this.props.app.sharedAPIWrapper().deleteImage(imageName, function(){
        //if (idx === array.length - 1) this.stopLoading(); -- DO NOT STATUS UPDATE SINCE ITS EXECUTED WHEN PAGE IS GOING OUT
      });
    });
  }
  //Form
  _validateForm() {
    const formLength = this.formEl.length;
    for (let i = 0; i < formLength; i++) {
      const elem = this.formEl[i];
      if (elem.required && !elem.value) {
        //
        var tabClasses = elem.closest(".childBox").className;
        var tabNumber = tabClasses.match("child[1-9]")[0].replace("child","");
        //change tab and end method..
        this.handleTabChange(tabNumber);
        return false;
      }
    } return true;
  }
  _calculateOtherValues() {
    var settings = this.props.app.sharedCache().getSettings();
    var user = this.props.app.sharedCache().getLoggerUser();
    //Calculations Milleage
    if (Utils.safelyGetNumericNestedObject(this.state.data,"travel.km") >= settings["longtravelkm"]) {
      var value = Utils.safelyGetNumericNestedObject(this.state.data,"travel.traveltime") * settings["timepaidlongdistance"];
      Utils.setNestedObject(this.state.data,"travel.travelreimbursement",value);
    } else {
      var shortDistance = parseFloat(user["timepaidshortdistance"] != undefined ? user["timepaidshortdistance"] : settings["timepaidshortdistance"]);
      var value = Utils.safelyGetNumericNestedObject(this.state.data,"travel.traveltime") * shortDistance;
      Utils.setNestedObject(this.state.data,"travel.travelreimbursement",value);
    }
    this.state.data["travel"]["traveltimegst"] = this.state.data["travel"]["travelreimbursement"] * settings["gstrate"];
    this.state.data["travel"]["kmreimbursement"] = Utils.safelyGetNumericNestedObject(this.state.data,"travel.km") * settings["valuekmdriven"];
    this.state.data["travel"]["kmgst"] = this.state.data["travel"]["kmreimbursement"] * settings["gstrate"];
    this.state.data["travel"]["totaltravel"] = this.state.data["travel"]["travelreimbursement"] + this.state.data["travel"]["kmreimbursement"];
    this.state.data["travel"]["totaltravelgst"] = this.state.data["travel"]["traveltimegst"] + this.state.data["travel"]["kmgst"];
    this.state.data["travel"]["totaltravelaftertax"] = this.state.data["travel"]["totaltravel"] + this.state.data["travel"]["totaltravelgst"];

    //Calculations Consulting
    var hourlyRate = parseFloat(user["hourlyrate"]);
    Utils.setNestedObject(this.state.data,"consulting.consultingpayment",(Utils.safelyGetNumericNestedObject(this.state.data,"consulting.consultinghours") * hourlyRate));
    this.state.data["consulting"]["consultinggst"] = this.state.data["consulting"]["consultingpayment"] * settings["gstrate"];
    this.state.data["consulting"]["consultingaftertax"] = this.state.data["consulting"]["consultingpayment"] + this.state.data["consulting"]["consultinggst"];
    //Calculations Total claim
    this.state.data["totalclaim"] = this.state.data["travel"]["totaltravel"] + this.state.data["consulting"]["consultingpayment"] + Utils.safelyGetNumericNestedObject(this.state.data,"othercosts");
    this.state.data["totalclaimgst"] = this.state.data["travel"]["totaltravelgst"] + this.state.data["consulting"]["consultinggst"];
    this.state.data["totalclaimaftertax"] = parseFloat(this.state.data["totalclaim"]) + parseFloat(this.state.data["totalclaimgst"]);
  }
}

//TODO
//view image
